.bankstatement-holder {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0 #ddd;
  border: 1px solid none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.textarealg {
  height: 83px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: hsl(214, 23%, 94%);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #101828;
  outline: none;
  border: none;
}
.each-profile-input-kyc {
  cursor: pointer;
}
.my_account_details {
  width: 100%;
  max-width: 384px;
  background-color: #fef0c7;
  padding: 16px;
  border-radius: 8px;
  margin-top: 25px;
  display: flex;
  gap: 16px;
}
.row-datatable {
  width: 100%;
}
/* 
.table-head {
  padding: 0px 15px 0px 15px;
  gap: 8px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.table-head tr {
  padding: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
  gap: 16px;
}
thead tr th {
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #873bff !important;
  width: 100%;
  min-width: 255px;
  border: none;
} */
.sort_icon-con {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}
.up-arrwo {
  width: 6px;
  height: 6px;
}
.down-arrow {
  width: 6px;
  height: 6px;
}
/* td {
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  overflow-wrap: break-word;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #344054;
  width: 100%;
  cursor: pointer;
} */
.text_details_check {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.text_details_check .info_imp {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
}
.info_impl {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.6px;
  text-align: left;
  color: #7a2e0e;
}
.text_details_check span {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #7a2e0e;
}
.mycardscroll::-webkit-scrollbar {
  width: 0px;
}

.mylabel {
  color: #667085;
  position: absolute;
  top: 0;
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  line-height: 16.2px;
}

.modal_new_lg .modal-content {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  border-radius: 16px !important;
}
.statement-head-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.shadow_tag {
  filter: drop-shadow(0px 0px 8px 0px #6f00ff0a)
    drop-shadow(0px 0px 7px 0px #1414140d);
}
.top_bottom_boder {
  box-shadow: 0 -0.5px 0.5px 0 #e7e9fb, 0 0.5px 0.5px 0 #e7e9fb;
}
.form-head-statement {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.lower_layer_layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lower_layer_layout h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}
.confirmButtonPin {
  background-color: #6f00ff;
  padding: 16px 24px 16px 24px;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  outline: none;
  border: none;
}
.list_usage {
  display: flex;
  gap: 24px;
  align-items: center;
  list-style: none;
  margin-bottom: 0px;
}
.no_invoice {
  max-width: 384px;
  padding: 8px 0px 8px 0px;
  border-radius: 8px;
  border: 1px solid #e7e9fb;
}
.no_mid_invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 20px;
}
.no_mid_invoice .text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  margin: 0px;
}
.md-invoice-lg .text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  margin: 0px;
}
.no_mid_invoice_lg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 20px;
}
.main_lg_invoice_search {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 8px 0px 8px;
  border-radius: 8px;
  gap: 8px;
  background-color: #eceff3;
  min-height: 48px;
}
.my_input {
  width: 100%;
  background-color: inherit;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.container_invoice_lg {
  background-color: #ffffff;
  padding: 0px 16px;
  border-radius: 8px;
  overflow: auto;
}
.middle_center_lg_vcenter .modal-dialog .modal-content {
  max-width: 375px;
  width: 100%;
  margin: 0px auto;
  border-radius: 16px !important;
}
.each_invoice_container_lg {
  padding: 16px 0px 16px 0px;
  display: flex;
  gap: 16px;
  align-items: center;
}
.each_invoice_container_lg:not(:last-child) {
  border-bottom: 1px solid #e7e9fb;
}
.each_sec_item_sm {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.invoice_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}
.mytime {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #667085;
}
.mytime_status {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #12b76a;
}
.no_mid_invoice_lg span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.top_steps_sect {
  height: 54%;
  width: 100%;
}
.mid_steps_sect {
  max-height: 170px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.circle_steps {
  height: 12px;
  position: relative;
  width: 12px;
  border: 1px solid #6f00ff;
  border-radius: 50%;
}
.bottom_steps_sect {
  height: calc(100% / 2 - 100px);
  width: 100%;
}
.active_steps {
  border: 1px solid #6f00ff;
}
.md-invoice-lg {
  display: flex;
  flex-direction: column;
}
.list_usage li {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
  cursor: pointer;
}
.currency_switch {
  padding: 16px;
  border-radius: 30px;
  border: 0.5px;
  gap: 8px;
  border: 0.5px solid #e7e9fb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.currency_board {
  display: flex;
  align-items: center;
  gap: 4px;
}
.currency_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #344054;
}
.title_currency {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.repayment_loan_progress {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.progress_line_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input_sign_code {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-top: 1px solid #e7e9fb;
}
.input_form_number {
  width: 100%;
  box-sizing: border-box;
  outline: none;
  background-color: inherit;
  border: none;
  appearance: none;
}

.balance_number {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #98a2b3;
}
.date_next_payment {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #667085;
}
.progress_line_title :nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}

.progress_line_title :nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: right;
  color: #3b3c4a;
}
.progress_line_complete_progress {
  height: 8px;
  border-radius: 20px;
  gap: 3px;
  background-color: #d7ddea;
  position: relative;
}
.progess_color_line {
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: #039855;
  overflow: hidden;
  border-radius: 49px;
}
.repayment_progress {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.currency_name {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #667085;
}
.top_tier_balance {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.container_line_stepper::after {
  position: absolute;
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #6f00ff;
  background-image: linear-gradient(#6f00ff);

  left: 10px;
  transform: translateX(8px);
}
.newline_container_stepper_line {
  position: absolute;
  content: " ";
  width: 2px;
  height: 100%;
  left: 0px;
  transform: translateX(8px);
}
.cicle_stepper {
  width: 20px;
  height: 20px;
  top: 0;
  border-radius: 50%;
  position: absolute;
  left: -30px;
  align-items: center;
  display: flex;
  border: 2px solid #6f00ff;
  justify-self: center;
  align-self: center;
  margin: auto 0px;
  z-index: 9999;
  background-color: #fff;
  top: calc(50% - 7px);
}
.cicle_stepper img {
  background-color: #6f00ff;
  border-radius: 100%;
}
.partner_count_lg {
  min-width: 352px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e7e9fb;
  gap: 16px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e7e9fb, #e7e9fb);
}
.inner_lg_count_partner {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.count_partner {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #101828;
}
.partner_name {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  margin: 0px;
  color: #667085;
}
.each_count_lg_partner_data {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.each_data_partner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.each_count_lg_partner_data:nth-child() {
  border-top: "1px solid red";
  border-bottom: "1px solid red";
}
.info_partner {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.info_partner_sm {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #344054;
}
.lower_layer_layout span {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #667085 !important;
}
.each_notify_col {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form_statement_lg {
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}
.form_lg_statementlg {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.each-lg-form-doubled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.lg_support {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.lg-support {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.support_lg_chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #e7e9fb;
  border-radius: 8px;
  background-color: #ffffff;
}
.img-chat-text {
  display: flex;
  gap: 4px;
  align-items: center;
}
.img_invite_lg_col {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.lg-comon-fl {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lg-comon-fl h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #101828;
}
.lg-comon-fl span {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 2% !important;
  text-align: center !important;
  color: #667085;
}
.btn-lg-lg-col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.btn-lg-gm {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: #eceff3;
  max-width: 324px;
  margin: 0px auto;
  width: 100%;
  text-align: center;
}
.invoice_table_lg {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.myname_title_amoutn {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}
.receipient_bank_details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.recipientDetails {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.btn-lg-gm div {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 2% !important;
  text-align: center !important;
  color: #6f00ff !important;
}
.keep_me_in_lg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
}
.box_sign_checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
}
.box_sign_checkbox input[type="checkbox"] {
  accent-color: #6f00ff;
}
.forgotten_passord {
  font-weight: 500;
  color: #6f00ff;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
}
.box_sign_checkbox span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #344054;
}
.btn-lg-gm span {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  text-align: center !important;
  color: #667085;
}
.invite-lg-col {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.text-hr {
  display: flex;
  flex-direction: column;
}
.text-hr div {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.text-hr span {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 2% !important;
  text-align: left !important;
  color: #98a2b3 !important;
}
.main-each-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 0.5px solid #e7e9fb;
  border-radius: 8px;
  background-color: #ffffff;
  max-width: 50%;
  width: 100%;
}
.main-each-layout span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}

.each-form-segment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.each-mainsecgemntt {
  height: 56px;
  background-color: #eceff3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 8px 16px 8px 16px;
}
.each-mainsecgemntt label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.bankstatement-holder input {
  background: inherit;
  border: inherit;
  outline: none;
}
.btn-send-request {
  background-color: #6f00ff;
  outline: none;
  border: none;
  padding: 16px 24px 16px 24px;
  max-width: 272px;
  width: 100%;
  border-radius: 8px;

  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  cursor: pointer;
}
.activities-con {
  max-width: 864px;
  margin: 0px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.tab-con {
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 8px;
  border-radius: 8px;
  background-color: #eceff3;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0px;
}
.tab-con li {
  text-align: center;
  width: 100%;
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  cursor: pointer;
}
.search-con-hg {
  height: 48px;
  background-color: #eceff3;
  display: flex;
  gap: 8px;
  padding: 0px 8px 0px 8px;
  border-radius: 8px;
}
.search-con-hg input {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
  background-color: inherit;
  width: 100%;
  border: none;
  outline: none;
}
.trans-notify-con {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.time-tile {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
  text-transform: uppercase;
}
.main-trans-notify-col {
  padding: 16px;
  border-radius: 8px;
  border: 0.5px;
  gap: 24px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #e7e9fb, #e7e9fb);
  border: 0.5px solid #e7e9fb;
}
.each-transact-notify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.each-transact-notify:not(:last-child) {
  box-shadow: 0 0.5px 0.5px 0 #e7e9fb;
}
.border_lg_ur:not(:last-child) {
  box-shadow: 0 0.5px 0.5px 0 #e7e9fb;
}
.left-sided-notify-barnenr {
  display: flex;
  gap: 8px;
  align-items: center;
}
.sec-tran-lg {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.big-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.statu-sect-lg {
  display: flex;
  gap: 4px;
  align-items: center;
}
.statu-sect-lg .services {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.statu-sect-lg .main-status {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12b76a;
}
.right-sided-locl {
  display: flex;
  flex-direction: column;
}
.list-item-details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border: 0.5px solid #e7e9fb;
  border-radius: 8px;
}
.each-list-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-col-span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.amount-title-gl {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.amount_remain {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #98a2b3;
}
.wallet_balance {
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  gap: 16px;
  margin: 0px auto;
  background: #f4f0ff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f00ff;
}
.border-title-lg {
  border: 0.5px dashed #d7ddea;
}
.list-item-step {
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
  padding-left: 0px;
  position: relative;
}
.step-processing-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
.top-step-tiele {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.main-request {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #6f00ff;
}
.btn-accept-list {
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #bfa6ff;
  background-color: #ebe4ff;
  border: none;
  outline: none;
  margin-bottom: 300px;
}
.white-bh-lg {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
}
.account_number_line {
  display: flex;
  gap: 8px;
}
.eac_account_detail {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #667085;
  text-align: right;
}
.top_nav_header_link {
  font-size: 14px;
  text-decoration: none;
  display: "flex";
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: #344054;
  align-items: center;
  font-weight: 500;
}
::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f00ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.no_fee {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #667085;
}
.active {
  text-decoration: none;
  border-radius: 30px;
  align-items: center;
  display: flex;
}
.active .span1 {
  color: #6f00ff !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: 2% !important;
  line-height: 19.6px !important;
}
.active .span2 {
  color: #1734de !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: 2% !important;
  line-height: 19.6px !important;
}
.active .span3 {
  color: rgb(15, 187, 230) !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: 2% !important;
  line-height: 19.6px !important;
}
.active .span4 {
  color: rgb(248, 84, 23) !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: 2% !important;
  line-height: 19.6px !important;
}
.active .span5 {
  color: rgb(44, 83, 242) !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: 2% !important;
  line-height: 19.6px !important;
}

.eachBusinessBoxContainer:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.eachBusinessBoxContainer:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.BusinessLoaderContianer::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.BusinessLoaderContianer::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.BusinessLoaderContianer::-webkit-scrollbar-thumb {
  background: rgb(111, 0, 255); /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners */
}

.BusinessLoaderContianer::-webkit-scrollbar-thumb:hover {
  background: rgb(111, 0, 255); /* Color of the scrollbar handle on hover */
}
.transfer-bank-logo {
  display: flex;
  gap: 8px;
  align-items: center;
}
.choose_bank {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.search-bank-lg-mf {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.beneficiary-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #344054;
}
.search-dl-lg {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 48px;
  padding: 0px 16px 0px 16px;
  background-color: #eceff3;
  border-radius: 8px;
}
.mybene-search {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  border-radius: 8px;
  background-color: inherit;
}
.bene-list {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.each-list-ben {
  padding: 16px;
}
.each-name-initial {
  display: flex;
  align-items: center;
  gap: 8px;
}
.intial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f0ff;
  display: flex;
  border: 1px solid #f4f0ff;

  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #7f56d9;
}
.name-bank {
  display: flex;
  flex-direction: column;
}
.name-link {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085 !important;
}
.incompletebusiness {
  position: relative;
}
.closure_up_absolute {
  padding: 0px 110px;
  height: 100vh;
  background-color: #fff transparent;
  position: absolute;
  width: 100%;
  z-index: 10;
  cursor: default;
}
p {
  margin-bottom: 0px !important;
}
.inner_business_content {
  padding: 16px;
  background-color: #6f00ff;
  border-radius: 16px;
  height: 76px;
  display: flex;
  gap: 16px;
  align-items: center;
}
.bank-link {
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left !important;
  color: #667085;
  text-wrap: nowrap;
  max-width: 200px;
}
.attach_invoice {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #6f00ff;
}

.upload_new {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #101828;
}
.sub_new {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #667085;
}
.modaltopdomwnradius:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.modaltopdomwnradius:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.input-settings {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 8px;
  background-color: #eceff3;
  outline: none;
  border: none;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}
.toggle-slider-switch {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
}
.save {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #344054;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 20.8px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15.08px;
  width: 15.08px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6f00ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6f00ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  height: 20px !important;
  width: 20px !important;
  top: -1px !important;
  box-shadow: 0px 3.9999969005584717px 5.99999475479126px -3.9999969005584717px #1018281a !important;

  box-shadow: 0px 0px 19.999984741210938px -2.99999737739563px #10182861;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.form-general-layout {
  width: 100%;
  max-width: 592px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 32px;
  margin: 100px auto;
}
@media only screen and (max-width: 820px) {
  .form-general-layout {
    width: 100%;
    padding: 0px;
  }
}
.mysummarytitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.title-re-lg {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.mytitle-answer {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #344054;
}
.myborder-slixe {
  border: 1px solid;

  border-image: radial-gradient(
      50.27% 7031199.26% at 50% 49.94%,
      #dce0f9 0%,
      rgba(231, 233, 251, 0) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  border-image-slice: 1;
}
.myclose-pin {
  border-bottom: 0.5px solid #e7e9fb;
}
.mybody-pin-lg {
  padding: 8px 24px 8px 24px;
}
.box-lg-ain {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.mybody-lgh-th {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title-header-lg {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title-header-lg h4 {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  margin: 0px;
}
.title-header-lg span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.btn-con-dil {
  padding-top: 20px;
  padding-bottom: 20px;
}
/**  Orders **/

.myorderdashboard {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 977px;
}
.supplier-container-buyer {
  width: 100%;
  height: 100%;
  padding: 32px;
}
.each-head-tiel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.supplier-container-buyer:not(:last-child) {
  border-right: 1px solid #e7e9fb;
}
.section-top-supliert {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.top-tier-lg {
  display: flex;
  flex-direction: column;
}
.top-tier-lg h4 {
  margin: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  padding: 0px;
}
.top-tier-lg span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.addbuyer {
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: right;
  background-color: #ebe4ff;
  color: #6f00ff;
  outline: none;
  border: none;
  cursor: pointer;
}
.each-value-buyer {
  max-height: 750px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}
.each-buyer-icon-lg {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.each-buyer-icon-lg label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
}
.main-each-lg {
  display: flex;
  justify-content: space-between;
  border: 0.5px solid #e7e9fb;
  padding: 16px;
  border-radius: 8px;
  gap: 24px;
  width: 100%;
  cursor: pointer;
}
.lg-sie-avater-lg {
  display: flex;

  gap: 8px;
}
.order-tag {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.top-buyer-add {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.my-titile-add-new-buyer {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0px;
  margin: 0px;
}
.top-buyer-add span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.form-select-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.each-form-group {
  height: 56px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 8px;
  outline: none;
  border: none;
  text-transform: capitalize;
  background-color: #eceff3;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.search-tradevu-user {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.search-tradevu-user label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.bord-trade-log {
  padding: 8px;
  border-radius: 8px;
  gap: 16px;
  background-color: #ffffff;
  height: 56px;
  align-items: center;
  display: flex;
}
.lg-mod-gl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.lg-mf-lg {
  display: flex;
  gap: 8px;
  align-items: center;
}
.lg-mf-lg span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.success {
  padding: 16px;
  border-radius: 8px;
  border: 1px;
  gap: 12px;
  min-height: 60px;
  border: 1px solid #6ce9a6;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #f6fef9;
}
.lg-success {
  display: flex;
  align-items: center;
  gap: 12px;
}
.main-sucees {
  padding: 2px 8px 2px 8px;
  border-radius: 16px;
  background-color: #ffff;
  color: #027a48;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
}
.ech-lg-hm {
  background-color: #ecfdf3;
  display: flex;
  padding: 4px 10px 4px 4px;
  border-radius: 16px;
  gap: 8px;
}
.ech-lg-hm span {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #027a48;
}
.buyer-details-lg-col {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.tag-details-lg {
  padding: 16px;
  border-radius: 8px;
  border: 0.5px solid #e7e9fb;
  width: 90%;
}
.item-details-action {
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 90%;
  width: 100%;
}
.each-item-actions {
  padding: 8px 16px 8px 16px;
  border-radius: 24px;
  gap: 8px;
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
}
.order-details-list-lg {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 90%;
  position: relative;
}
.order-details-list-lg label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.days-lg-line {
  display: flex;
  align-items: center;
  gap: 8px;
}
.days-lg-line span {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
}
.line {
  border: 0.5px solid #e7e9fb;
  width: 100%;
}
.order-btn {
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  display: flex;
  align-items: center;
  background-color: #ebe4ff;
  color: #6f00ff;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: right;
  box-shadow: 0px 2px 4px -2px #1018280f;
  outline: none;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.label-order-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.label-order-title label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}
.order-items-col {
  min-height: 56px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  cursor: pointer;
}
.tile-col-lg-kl {
  display: flex;
  gap: 8px;
  align-items: center;
}
.tile-col-lg-kl span {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #6f00ff;
}
.uploadpurchaseorder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.Noorder {
  font-size: 20px;
  font-weight: 800;
  color: #667085;
}
.order-id-lg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.order-id-lg span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
  text-transform: capitalize;
}
.lg-bm-yh {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.ech-form-lg-hl {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 90%;
}
.ech-form-lg-hl label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054;
}
.ech-form-lg-hl input {
  height: 56px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 8px;
  background-color: #e7e9fb;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.button-lg-con {
  margin-top: 150px;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
}
.btn-accept-decline {
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  min-width: 195px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  justify-content: center;
  align-items: center;
  display: flex;
}
.decline {
  background-color: #fee4e2;
  color: #f04438;
}
.accept {
  background-color: #12b76a;
  color: #f6fef9;
}
.invoice_add {
  padding: 8px 24px 8px 24px;
  gap: 24px;
}
.body-invoice-a {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.body-invoice-a h4 {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  margin: 0px;
  padding: 0px;
}
.top-lg-mk {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.top-lg-mk h5 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #98a2b3;
  margin: 0px;
  padding: 0px;
}
.tp-lg-mk-fl {
  padding: 0px 16px 0px 16px;
  border-radius: 8px;
  max-height: 500px;
  overflow: auto;
}
.top-bodal-activate {
  position: absolute;
  background-color: rgb(111, 0, 255);
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 8px;
  cursor: pointer;
  display: none;
}
.activate-btn {
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  color: #101828;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 9000;
}
.myboundal-row:hover .top-bodal-activate {
  display: flex;
}
.myboundal-row:hover .toglle-white {
  color: #ffff !important;
}
.saveInvoice {
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  background-color: #ebe4ff;
  color: #6f00ff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  width: 100%;
  max-width: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.suceessinvoice {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invoice-drag-empty {
  display: flex;
  flex-direction: column;
  gap: 192px;
}
.main-drag-lg {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.text-lg-main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}
.text-lg-main h3 {
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #101828;
}
.text-lg-main span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #667085;
}
.text-lg {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #667085;
}
.goto {
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #6f00ff;
  outline: none;
  border: none;
  cursor: pointer;
}
.left-col-static-nav {
  height: 100%;
  max-width: 384px;
  background-color: #fff;
  border-right: 1px solid #e7e9fb;
  width: 100%;
  min-height: 1000px;
  padding: 32px;
}
.interrior-lg-sm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
}
.each-scale-ratio {
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
}
.inner-lg-mf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 6px;
}
.img-lg-text {
  display: flex;
  align-items: center;
  gap: 8px;
}
.img-lg-text span {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #101828;
}
.right-side-account-details {
  width: 100%;
  height: 100%;
  padding: 32px;
}
.miid-side-details {
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.top-details-lg-mgph {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.top-details-lg-mgph span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}
.form-details-lg-hl {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.change-profile {
  display: flex;
  align-items: center;
  gap: 8px;
}
.change-profile span {
  padding: 0px 16px 0px 16px;
  border-radius: 8px;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f00ff;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.each-profile-input {
  height: 56px;
  border-radius: 8px;
  background-color: #eceff3;
  display: flex;
  gap: 8px;
  padding: 8px 16px 8px 16px;
  flex-direction: column;
}
.each-profile-input label {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.each-profile-input input,
.each-profile-input select {
  width: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;
  background-color: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.uploadfile-each {
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
  border: 0.5px solid #c0cad8;
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: #eceff3;
  margin-top: -15px;
}
.text-img-lg {
  display: flex;
  flex-direction: column;
}
.head-tag-gl {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.small-text-lg-pt {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  text-align: left !important;
  color: #667085 !important;
}
.update-profile {
  outline: none;
  border: none;
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  max-width: 280px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #bfa6ff;
}
.top-details-lg-mgph-close {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.md-text-lg-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.md-text-lg-text h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: #101828 !important;
  padding: 0px;
  margin: 0px;
}
.md-text-lg-text span {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.form-close-reason {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.text-reason {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 8px;
  background-color: #eceff3;
  height: 120px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.closebtn {
  padding: 16px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  max-width: 272px;
  background-color: #f04438;
  color: #fffbfa;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
  outline: none;
  border: none;
}
.closeinput {
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 8px;
  background: #eceff3;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
  width: 100%;
  border: none;
  outline: none;
}
.confirm_term {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.sting-validate {
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
}
.tems-privacy {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
  text-decoration: underline;
}
.swal2-backdrop-show {
  z-index: 90000;
}
.invoicmodal:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.invoicmodal:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.emtyp-lg {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detail-document-order {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 24px 8px 24px;
}
.detail-document-order label {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}
.lg-flex-gl {
  display: flex;
  gap: 24px;
  align-items: center;
}
.shareClass {
  max-width: 2000px;
  width: 100%;
  margin: 0px auto;
  padding: 30px;
}
.absolute-img {
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 20px;
}
.invoice-body {
  height: 100%;
  padding: 30px;
  border-top: 3px solid #6f00ff;
  position: relative;
  background-color: #ffffff;
  justify-content: flex-end;
}
.invoice-body h3 {
  position: absolute;
  left: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f00ff;
  left: 30px;
}
.bodey-details {
  position: absolute;
  top: 170px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.shaare-deails {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.invoice-date-lg {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: #667085;
}
.to-from-lg-mg {
  display: grid;
  gap: 24px;
  justify-content: flex-end;
}
.to-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.my-share-table {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  color: #667085 !important;
}
.total-sub-lg {
  display: grid;
  gap: 16px;
  justify-content: flex-end;
  border-top: 1px solid #d7ddea;
  border-bottom: 1px solid #d7ddea;
  padding-top: 10px;
  padding-bottom: 10px;
}
.each-item-sub-lg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 249px;
}
.regular-span {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.regular-totla {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: "
#101828";
}
.text-lg-mrbottom {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
  padding: 32px 0px 32px 0px;
}
.no-record {
  text-align: center;
  font-size: 14px;
}
.each-profile-input-kyc {
  display: flex;

  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #e7e9fb;
  justify-content: space-between;
}
.lg-bm-fl-g {
  display: flex;
  flex-direction: column;
}
.lg-bm-fl-g h4 {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #101828;
}
.lg-bm-fl-g span {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #667085;
}
.lg-mg-lo-pl {
  display: flex;
  gap: 8px;
  align-items: center;
}
.top-level-beneficiary-log {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.h3-level-span {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.h3-level-span h4 {
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
  padding: 0px;
  margin: 0px;
}
.h3-level-span span {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}
.input-search-beneficiary-lg {
  height: 48px;
  border-radius: 8px;
  background-color: #eceff3;
  padding: 0px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}
.input-search-beneficiary {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
  outline: none;
  border: none;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
}

.modal .modal-content {
  border-radius: 20px;
  max-height: 80%;
}