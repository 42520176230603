.businessswitch{
    width: 100%;
    height: 100%;
    background-color: #6F00FF;
    max-width: 1800px;
    margin: 0px auto;
    position: relative;
    min-height: 2000px;
}
.businessheader{
padding: 10px 80px 10px 80px;
border: 0px 0px 1px 0px;
gap: 10px;
display: flex;
align-items: center;
justify-content: center;
}
.imgworld_account_business{
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.layout_top_after{
    position: absolute;
    width: 100%;
    top: 0;
}
.business_navbar{
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.back_to_dashboard{
    display: flex;
    padding: 8px 0px 8px 0px;
    gap: 24px;
    align-items: center;
}
.inner_back_header_naigate{
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}
.inner_back-header_naigate h3{
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:#F4F0FF;
padding: 0px;
margin: 0px;

}
.h3_top_l{
    font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:#F4F0FF;
padding: 0px;
margin: 0px;
}
.key_back{
    color:#F4F0FF

}
.table_trade_box_layout{
    margin-top: 100px;
    padding: 10px 80px 10px 80px;

}
.mid_layout_account{
    display: flex;
    justify-content: space-between;
    gap: 136px;
}
.left_layout_account{
    flex-direction: column;
    display: flex;
    gap: 32px;
    max-width: 384px;
    width: 100%;

}
.left_layout_account h1{
font-size: 32px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
color: #FCFCFD;
padding: 0px;
margin: 0px;

}
.layout_wide_below_account{
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 8px;
    padding: 24px;

}
.inner_layout_wide_below_account{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 56px;
}
.top_inner_mid_below_layout{
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.avater_layout_lg{
    display: flex;
    gap: 16px;
    align-items: center;
}
.avater_name_layout{
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.avater_layout_lg h2{
font-size: 24px;
font-weight: 600;
line-height: 32px;
letter-spacing: 0em;
text-align: left;

color: #101828;
padding: 0px;
margin: 0px;

}
.avater_layout_lg span{
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: 
#344054;

}
.layout_details_account{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.each_details_layou{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.each_details_layou :first-child{
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #667085;

}
.each_details_layou :last-child{
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
color:#344054
}
.inner_bottom_layout{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn_layout_account{
padding: 16px 24px 16px 24px;
border-radius: 8px;
gap: 8px;
display: flex;
align-items: center;
gap: 8px;
background-color: #F4F0FF;
outline: none;
border: none;
cursor: pointer;

}
.btn_layout_account span{
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.02em;
text-align: left;
color: #6F00FF;

}
.right_layout_side{
    padding: 56px;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    width: 100%;
    background-color:  #F4F0FF;
    max-height: 1800px;
}
.mid_bg_lg_layout{
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: 100%;
    width: 100%;
}
.h1_layout{
font-size: 48px;
font-weight: 600;
line-height: 52px;
letter-spacing: 0em;
text-align: left;
color: #4E03AF;

}
.sm_formnot_form{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.title_layout{
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #272627;
padding: 0px;
margin: 0px;

}
.rm_bg_lg{
    border-radius: 8px;
    border: 1px solid #D9CDFF;
    display: flex;
    flex-direction: column;
    max-height: 1400px;
    overflow-y: auto;

}
.each_rm_lg{
padding: 16px;
border-radius: 8px;
border: 0px 0px 0.5px 0px;
gap: 16px;
display: flex;
justify-content: space-between;
align-items: center;
cursor: pointer;

}
.usinessname{
font-size: 18px ;
font-weight: 600 ;
line-height: 24px ;
letter-spacing: 0em ;
text-align: left;
color: 
#101828 ;

}
.single_buttom_lg{
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #667085;

}