
.body{
  background-color: #F6F8FA;
}

.slidecontainer {
  width: 100%;
  position: relative; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #D7DDEA;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 29px; 
  background: #fff;
  cursor: pointer;
  border: 2px solid #6F00FF;
  content: "dd";
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #6F00FF;
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255,85,0, .1)
}
input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255,85,0, .2)
}
input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255,85,0, .2)    
}




