.modal-dialog {
  width: calc(100% - 60%) !important;
  margin: 20px auto;
  max-width: 500px;
}
.modal-dialog-override{
width: 100% !important;
margin: 0px auto;
max-width: 100% !important;
}
.modal-content-override{
    max-height: 100% !important;
}

.modal-header {
  display: block !important;
}
.btn-close {
  font-size: 12px;
}
.modal-content {
  background-color: #f6f6f6 !important;
  /** border-radius: 16px 0px 0px 16px !important; **/
}
/* Modal Dialog (Wrapper) */
.primaryModal .modal-dialog {
  width: 90%;
  max-width: 500px;
  margin: 5vh auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Body */
.primaryModal .modal-body {
  padding: 0px !important;
}

/* Modal Content */
.primaryModal .modal-dialog .modal-content {
  background-color: #6f00ff !important;
  border-radius: 16px;
  width: 100%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
}

/* Modal Inner Body */
.primaryModal .modal-dialog .modal-content .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .primaryModal .modal-dialog {
    width: 95%;
    max-width: 500px;
  }

  .primaryModal .modal-dialog .modal-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .primaryModal .modal-dialog .modal-content .modal-body {
    padding: 16px;
  }
}

@media (max-width: 480px) {
  .primaryModal .modal-dialog {
    width: 100%;
    max-width: 90%;
    margin: 10vh auto;
  }

  .primaryModal .modal-dialog .modal-content {
    border-radius: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .primaryModal .modal-dialog .modal-content .modal-body {
    padding: 12px;
  }
}

.myBilltype .modal-dialog {
  margin: 0px auto;
}
.myBilltype .modal-dialog .modal-content {
  border-radius: 16px;
}
.bg-big {
  background-color: #f6f8fa;
  /*background-image: url('../assets/images/bgworld2.svg');*/
  background-repeat: no-repeat;
  background-position-x: right;
  /*background-position-y: bottom;*/
  height: calc(100vh - 80px);
  position: relative;
}

.modal modal-content {
  border-radius: 30px;
}

@media screen and (max-width: 768px) {
  .myheadertext {
    max-width: 100% !important;
    width: 100%;
    font-size: 0.8rem !important;
  }
  .myaccount {
    font-size: 0.7rem !important;
  }
}
@media screen and (max-width: 768px) {
  .modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    margin: 20px auto !important;
  }
}
