textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0 #ddd;
  border: 1px solid none;
}
.mycardscroll::-webkit-scrollbar{
    width: 0px;
}
.mylabel{
  color: #667085;
  position: absolute;
  top: 0;
  font-size:12px;
  font-weight: 500;
  margin-left: 12px;
  line-height: 16.2px;
  
}
