.rightsidemodal .modal-dialog .modal-content {
  margin: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px;
  background-color: #ffff !important;
  /**height: 1200px; **/

  min-height: 100vh;
}
.mysidedetail .modal-dialog .modal-content {
  min-height: 100vh !important;
  margin: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px;
  background-color: #ffff !important;
  height: 100%;
  border-radius: 16px 0px 0px 16px !important;
}

.rightsidemodal .modal-dialog {
  margin-right: -20px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  min-width: 528px !important;
}
.addpaymentmodal .modal-dialog .modal-content {
  max-width: 360px !important;
  border: none !important;
  border-radius: 16px !important;
  width: 100% !important;
}
.mysidedetail .modal-dialog {
  margin-right: -20px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  min-width: 528px !important;
}

.mycenteredlarge .modal-dialog {
  max-width: 1230px !important;
}
.mycenteredlarge .modal-dialog .modal-content {
  background-color: #f6f6f6 !important;
  width: 100% !important;
  max-width: 375px;
  margin-left: 30px;
}
.mycenteredlarge .modal-dialog-centered {
  width: 100% !important;
}
.myaddwallet .modal-dialog {
  max-width: 384px;
  max-height: 704px;
}
.myaddwallet .modal-dialog .modal-content {
  background-color: #f6f6f6 !important;
  border-radius: 30px;
}
.btn-close {
  color: "#101828 !important";
}
