@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=block');


@layer base {

  *,
  *::before,
  *::after{
    font-family: "Rubik", sans-serif;
  }

  body {
    @apply font-sans bg-grey-bg text-main scroll-smooth
  }

  input,
  textarea,
  select {
    @apply !outline-none !border-none active:!outline-none active:!border-none focus:!outline-none focus:!border-none bg-transparent !shadow-[inset_0_0_0_1000px_#f9fafb]
  }

  select {
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(102,112,133)'/></g></svg>") no-repeat;
    background-position: center right 16px;
    background-size: 15px auto;
    background-color: #fff;
  }

  button {@apply outline-none border-none}

  a {
    @apply !text-main !no-underline
  }
}


@layer components {}


@layer utilities {
  .navbar-gradient {
    @apply border-b [border-image-slice:1] [border-image-source:radial-gradient(50.27%_7031199.26%_at_50%_49.94%,#dce0f9_0%,rgba(231,_233,_251,_0)_100%)]
  }
}
.customInput {
  @apply !shadow-none bg-[#ECEFF3] w-full rounded-[8px] border-0 outline-0 h-[56px] px-4 py-2 tracking-[2%] font-normal text-[16px];
}